<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>SMS Schedule</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="extra_info px-3 py-2 mb-5">Delivery of a single message can take up to 5 minutes from the scheduled time.</div>
                    <schedule-tab v-model="schedule" ref="sms-center-schedule" />
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" class="btn save_btn" @click="handleScheduleSms">Schedule</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ScheduleTab = defineAsyncComponent(() => import('@/components/ScheduleTab'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Scheduled Sms',

        data () {
            return {
                schedule: {},
            }
        },

        props: {
            modelValue: Boolean,
            contactId: [Number, String],
            sms: String,
            media: String,
        },

        emit: ['update:modelValue'],

        components: {
            ScheduleTab
        },

        computed: mapState ({
            loader: state => state.smsCenterModule.scheduledSmsLoader,
        }),

        methods: {
            ...mapActions ({
                scheduleSMS: 'smsCenterModule/scheduleSMS',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleScheduleSms() {
                const vm  = this;

                const params = {
                    contact_id: vm.contactId,
                    source: 'sms chat',
                    sms: vm.sms,
                    media: [vm.media],
                    delay: vm.schedule,
                };

                vm.scheduleSMS(params).then((result) => {
                    if (result) {

                        if (vm.$parent.$parent && vm.$parent.$parent.sms && vm.$parent.$parent.media) {
                            vm.$parent.$parent.sms   = '';
                            vm.$parent.$parent.media = '';
                        } else {
                            vm.$parent.sms   = '';
                            vm.$parent.media = '';
                        }

                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
